@import 'libs/vars';
@import 'libs/functions';
@import 'libs/mixins';
@import 'libs/skel';
@import 'font-awesome.min.css';
@import 'libs/fonts';

/*
	Massively by HTML5 UP
	html5up.net | @ajlkn
	Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
*/

	@include skel-breakpoints((
		xlarge: '(max-width: 1680px)',
		large: '(max-width: 1280px)',
		medium: '(max-width: 980px)',
		small: '(max-width: 736px)',
		xsmall: '(max-width: 480px)',
		xxsmall: '(max-width: 360px)'
	));

	@include skel-layout((
		reset: 'full',
		boxModel: 'border',
		grid: ( gutters: 1.5rem ),
		containers: 100%,
		breakpoints: (
			xlarge: (
				containers: 720px
			),
			large: (
				containers: 620px
			),
			small: (
				containers: 100%
			)
		)
	));

	@mixin color($p) {
		@include color-typography($p);
		@include color-box($p);
		@include color-button($p);
		@include color-form($p);
		@include color-list($p);
		@include color-section($p);
		@include color-table($p);
		@include color-pagination($p);
	}

// Base.

	@import 'base/page';
	@import 'base/typography';

// Component.

	@import 'components/box';
	@import 'components/button';
	@import 'components/form';
	@import 'components/icon';
	@import 'components/image';
	@import 'components/list';
	@import 'components/map';
	@import 'components/section';
	@import 'components/table';
	@import 'components/pagination';

// Layout.

	@import 'layout/wrapper';
	@import 'layout/intro';
	@import 'layout/header';
	@import 'layout/nav';
	@import 'layout/main';
	@import 'layout/footer';
	@import 'layout/navPanel';
	@import 'layout/toc';
  @import 'layout/cookieBanner'
