#cookie-notice {
  width: "100%";
}

#cookie-notice {
  z-index: 10;
  display: none;
  text-align: center;
  position: sticky;
  bottom: 0;
  background: _palette(bg);
  color: _palette(fg);
  border: 3px solid _palette(border);
  padding: 10px 20px 0px 20px;
}
