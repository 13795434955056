  .map {
    height: 20rem;

    .heading {
  		font-family: _font(family-heading);
  		font-weight: _font(weight-heading);
  		line-height: 1.5;
  		letter-spacing: 0.075em;
  		text-transform: uppercase;
  		margin: 0 0 0.5em 0;

  		a {
  			border-bottom: 0;
  			color: inherit;
  			text-decoration: none;
  		}
    }
  }
