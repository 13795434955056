ul#markdown-toc {
  list-style: none none inside;
  padding: 0;
  > li {
    padding-top: 0.1rem;
  }
  li {
    padding-left: 2rem;
    font-size: 1rem;
    font-weight: 600;
    font-family: _font(family-heading);
    ul {
      list-style: none none inside;
      padding: 0;
      margin: 0;
    }
  }
}
